<template>
  <div id="body">
    <div class="header">
      <p>广告商充值</p>
    </div>
    <div class="content">
      <ul>
        <li>
          <p>充值账号：{{detailInfo.advUserName}}</p>
        </li>
        <li>
          <p>充值账号：{{detailInfo.tel}}</p>
        </li>
        <li>
          <p>当前余额：<span class="moneySpan">{{$util.func.setMoney(detailInfo.balance)}}</span></p>
        </li>
        <li style="margin-top: 10px;">
          充值金额：
          <el-radio-group v-model="addMoney" size="medium">
            <el-radio-button class="radioButton" v-for="(item,index) in rechargeMoney" :key="index" :label="item.value">
              {{item.money}}
            </el-radio-button>
          </el-radio-group>
          <!-- <el-input class="input" placeholder="请输入金额" v-model="addMoney" size="medium">
            <template slot="append">元</template>
          </el-input> -->
        </li>
        <li style="margin-top: 10px;">
          广告商折扣：
             <el-input-number v-model="advPercent" :min="0" :max="100" :precision="0"  label="80表示8折"></el-input-number>
             <span>&nbsp;&nbsp;100表示不打折 80表示8折</span>
        </li>
        <li>
          <el-button class="submitButton" size="medium" type="primary" @click="recharge()">立即充值</el-button>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
  import {
    advShangRecharge
  } from '@/api/adminApi.js'
  export default {
    data() {
      return {
        detailInfo: JSON.parse(this.$route.params.detailInfo), //广告商信息
        addMoney: 10, //充值金额，默认10元
        advPercent : 0,
        rechargeMoney: [ //充值金额选择表
          {
            "money": '10元',
            "value": 10
          }, {
            "money": '50元',
            "value": 50
          }, {
            "money": '100元',
            "value": 100
          }, {
            "money": '200元',
            "value": 200
          }, {
            "money": '500元',
            "value": 500
          }, {
            "money": '1000元',
            "value": 1000
          }
        ]
      }
    },
    created() {
      this.addMoneyRoute()
    },
    methods: {
      addMoneyRoute() {
        if (!!this.$route.params.addMoney) {
          this.addMoney = this.$route.params.addMoney;
        }
      },
      recharge() {
        var that = this

        const data = {
          num: this.$util.func.getMoney(this.addMoney),
          advUserId: this.detailInfo.id,
          advPercent: this.advPercent
        }
        if(this.advPercent == 0){
          this.$message.error('请填写广告商折扣')
          return  false;
        }
        advShangRecharge(data).then(res => {
          if (res.status.code == 1) {
            this.$message.success("充值成功，三秒后跳转到广告商列表页面")
            window.setTimeout(function() {
              that.$router.push("/advShangList")
            }, 3000)
          } else {
            this.$message.error(res.status.msg)
          }
        }).catch(err => {
          console.log(err)
        })
      },
      aaa(value) {
          console.log(value)
      },
    },
    computed: {

    }
  }
</script>

<style lang="scss" scoped>
  #body {
    background-color: #F0F2F5;
  }

  .header {
    width: 100%;
    height: 15vh;
    background: white;
    display: flex;

    p {
      font-size: 20px;
      font-weight: bold;
      line-height: 10vh;
      margin-left: 47px;
    }
  }

  .content {
    display: flex;
    justify-content: center;
    width: 95%;
    height: 100%;
    margin: 0 auto;
    margin-top: 25px;
    background: white;

    ul {
      display: flex;
      flex-direction: column;
      list-style-type: none;
      // margin-left: -5vw;
      margin-top: 5vh;

      li {
        text-align: left;
        height: 8vh;
        line-height: 8vh;

        p {
          height: 30px;
          line-height: 30px;
        }

        .moneySpan {
          font-size: 25px;
          color: #409EFF;
        }

        .radioButton {
          margin-right: 5px;
          border-left: 1px solid #DCDFE6;
        }

        .submitButton {
          margin-left: 100px;
          margin-top: 30px;
        }

        .input {
          width: 120px;
        }
      }
    }
  }
</style>
